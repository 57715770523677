import { getSalesId } from './general';

import request from 'src/utils/request';
import { CONNECT } from 'src/utils/environment';

export function getPackageList ({ salesId }) {
  const inntopiaSalesId = getSalesId(salesId);

  let parameters = {
    id: inntopiaSalesId,
    salesId: inntopiaSalesId,
  };

  return new Promise((resolve, reject) => {
    request({
      url: `${CONNECT}/packages`,
      method: 'GET',
      params: parameters
    })
    .then((response) => {
      const data = response.data || null;

      if (data.result) {
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/getPackageList', err);
      reject(err)
    });
  })
};

export function getPackage ({ salesId, packageId = '', code = '', startDate = false, endDate = false, adultCount = false, childCount = false }) {
  const inntopiaSalesId = getSalesId(salesId);

  let parameters = {
    id: inntopiaSalesId,
    salesId: inntopiaSalesId,
    packageId: packageId,
    code: code,
  };

  if (startDate && endDate) {
    parameters.startDate = startDate;
    parameters.endDate = endDate;
  }

  if (adultCount) {
    parameters.adultCount = adultCount;
    if (childCount) {
      parameters.childCount = childCount;
    }
  }

  return new Promise((resolve, reject) => {
    request({
      url: `${CONNECT}/packages/package`,
      method: 'GET',
      params: parameters
    })
    .then((response) => {
      const data = response.data || null;

      if (data.result) {
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/getPackage', err);
      reject(err)
    });
  })
};

export function getPackageComponent ({salesId, packageId, startDate, endDate, adults, children, componentId, componentDate, code = ''}) {
  const inntopiaSalesId = getSalesId(salesId);

  let parameters = {
    id: inntopiaSalesId,
    salesId: inntopiaSalesId,
    packageId,
    startDate,
    endDate,
    adultCount: adults,
    childCount: children,
    onlyComponent: componentId,
    code,
  };

  if (componentDate) {
    parameters.componentDate = componentDate;
  }

  return new Promise((resolve, reject) => {
    request({
      url: `${CONNECT}/packages/package`,
      method: 'GET',
      params: parameters
    })
    .then((response) => {
      const data = response.data || null;

      if (data.result) {
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/getPackageComponent', err);
      reject(err)
    });
  })
};
