import React from "react";
import ToolbarCommon from "../toolbar/toolbar.common";

export default class MobileToolbar extends ToolbarCommon {

    public get style(): any {
        const {toolbar} = this.props;
        return {
            '--text-color': toolbar.text_color?.value,
            '--hover-color': toolbar.hover_color?.value,
            '--background': toolbar.background_color?.value,

            '--dropdown-background': toolbar.dropdown_menu_background_color?.value || '#fff',
            '--dropdown-active-color': toolbar.text_color?.value,
            '--dropdown-text-color': toolbar.unselected_item_color?.value,
            '--dropdown-hover-color': toolbar.hover_color?.value,
        }
    }

    get display() {
        if (this.props.toolbar.mobile_toolbar_display === "fixed") {
            return "flex sticky";
        }
        if (this.props.toolbar.mobile_toolbar_display === "sticky") {
            return this.props.isScrollingUp ? "flex sticky" : "hidden";
        }
    }


    get items() {
        let itemsToReturn = [];
        if (!this.props.isInApp) itemsToReturn.push(...this.props.toolbar.items.filter(item => item.item.mobile_fixed_at_top === true).map((item, index) => this.renderToolbarItem(item, index)));
        
        if (this.props.toolbar.with_currency_selector && this.props.toolbar.currency_selector_fixed_at_top && !this.props.isInApp) {
            itemsToReturn.push(this.renderCurrencyPicker());
        }
        if (this.props.toolbar.with_language_selector && this.props.toolbar.language_selector_fixed_at_top && !this.props.isInApp) {
            itemsToReturn.push(this.renderLanguagePicker());
        }
        if (this.props.toolbar.user_profile && this.props.toolbar.user_profile_fixed_at_top) {
            itemsToReturn.push(this.renderMyAccount());
        }
        if (this.props.toolbar.shopping_cart && this.props.toolbar.shopping_cart_fixed_at_top) {
            itemsToReturn.push(this.renderCart());
        }
        
        return itemsToReturn;
    }

    public render() {
        if (this.items.length === 0) return null;
        const heightClass = this.props.scrolledDown ? 'h-[40px]' : 'h-[56px]';

        return <div id="toolbar" className={`${this.display} w-full lg:hidden top-0 z-[101] px-[24px] toolbar ${heightClass} bg-[color:var(--background)] ${this.shadow}`} style={this.style}>
            <div className="mx-auto w-full max-w-7xl flex flex-row items-center justify-end relative gap-6">
                {this.items}
            </div>
        </div>
    }
}