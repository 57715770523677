import React from "react";
import ToolbarCommon from "./toolbar.common";

export default class ToolbarV1 extends ToolbarCommon {

    get full_width() {
        return this.props.toolbar.full_width;
    }

    get fullwidthClassname(){
        if (this.full_width) return "";
        return "max-w-7xl";
    }

    get alignment() {
        const { alignment } = this.props.toolbar;
        if (alignment === "center") return "justify-center";
        return "justify-end"
    }


    public get style(): any {
        const {toolbar} = this.props;
        return {
            '--text-color': toolbar.text_color?.value,
            '--hover-color': toolbar.hover_color?.value,
            '--background': toolbar.background_color?.value,

            '--dropdown-background': toolbar.dropdown_menu_background_color?.value || '#fff',
            '--dropdown-active-color': toolbar.text_color?.value,
            '--dropdown-text-color': toolbar.unselected_item_color?.value,
            '--dropdown-hover-color': toolbar.hover_color?.value,
        }
    }

    public render() {
        const heightClass = this.props.scrolledDown ? 'h-[40px]' : 'h-[56px]';

        return (
        <>
            {this.renderChangeStoreDisclaimer()}
            <div id="toolbar" className={`hidden lg:flex sticky w-full top-0 z-[101] px-[24px] lg:px-[40px] toolbar flex-row ${heightClass} bg-[color:var(--background)] ${this.shadow}`} style={this.style}>
                <div className={`mx-auto w-full ${this.fullwidthClassname} flex flex-row items-center ${this.alignment} relative gap-6`}>
                    {this.props.toolbar.items.map((item, index) => this.renderToolbarItem(item, index))}
                    {this.renderLanguagePicker()}
                    {this.renderCurrencyPicker()}
                    {this.renderMyAccount()}
                    {this.renderCart()}
                </div>
            </div>
        </>
        )
    }
}