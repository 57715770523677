import React from "react";
import NavigationBarMenu from "./navigationbar.menu";
import NavigationBarLink from "./navigationbar.link";

import "./navigationbar.scss";

type Props = {
    language: string;
    defaultLanguage: string;
    items: Array<{ position: number; item: TNavigationItem; }>;
    flyout_promo_box?: React.ReactNode;
    chevrons_on_menus: boolean;
    flyout_menu_opening: TFlyoutMenuOpening;
    collapse_first_level_items: boolean;
}

export default class NavigationBarNavigation extends React.Component<Props> {
    private get items(){
        return (this.props.items || []).filter(({item}) => item.hide !== "yes")
    }
    render(): React.ReactNode {
        const {defaultLanguage, language, flyout_menu_opening, collapse_first_level_items} = this.props;
        return this.items.map(({item}, idx) => {
            if (item.item_type === "Link") return <NavigationBarLink depth={0} key={`navbar_link_${idx}`} item={item} language={language} defaultLanguage={defaultLanguage} />
            if (item.item_type === "Menu") return <NavigationBarMenu collapse_first_level_items={collapse_first_level_items} flyout_menu_opening={flyout_menu_opening} chevrons_on_menus={this.props.chevrons_on_menus} depth={0} key={`navbar_menu_${idx}`} item={item} language={language} defaultLanguage={defaultLanguage} />
            if (item.item_type === "Menu with link") return <NavigationBarMenu collapse_first_level_items={collapse_first_level_items} flyout_menu_opening={flyout_menu_opening} chevrons_on_menus={this.props.chevrons_on_menus} depth={0} key={`navbar_menulink_${idx}`} item={item} language={language} defaultLanguage={defaultLanguage} />
            return null;
        });
    }
}