import axios from "axios";

import { CONNECT, CONNECT_CLIENT, INNTOPIA_SALES_ID } from "src/utils/environment";
import { setSession, getSession, clearStorage, getCurrencyExchange, setCurrencyExchange, getCurrentCurrency, setCurrentCurrency, setChangeStoreDisclaimer } from "src/utils/localStorage";
import checkDevice from "src/utils/device";
import { fromISO, differenceInHours } from "src/utils/datefns";
import { generateSession as generateInntopiaSession, getCurrencyExchange as getCurrencyExchangeService, getSalesId } from "src/services/inntopia";
import { isRunTime } from "src/utils/isRunTime";
import { handleRefresh } from "src/utils/routing";
import { defaultCurrency, currencies } from "src/../src-dynamic/destination.currencies";
import { currencyFromIp, selectCurrency } from "src/utils/currency";

export const generateSession = () => {
  const userAgent = checkDevice();
  return new Promise((resolve, reject) => {
    axios
    .post(`${CONNECT}/checkout/session`, {
      client: CONNECT_CLIENT,
      session: "",
      userAgent: userAgent,
    })
    .then((response) => {
      if (response && response.data && response.data.uuid) {
        response.data.timeStamp = new Date().getTime();
        setSession(response.data);
        resolve(response.data)
      } else {
      }
    });
  })
};

const changeStoreSession = (currency) => {
  setCurrentCurrency(currency);
  setChangeStoreDisclaimer(null);
  setSession({});
  setCurrencyExchange({});

  if (isRunTime()) {
    handleRefresh();
  }
};

const getCurrencyFromQueryString = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const currencyParam = urlParams.get('currency');
  if (currencyParam) {
    return selectCurrency(currencies, currencyParam);
  }
  return null;
};

const removeCurrencyFromQueryString = () => {
  if (isRunTime()) {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('currency');
    window.history.replaceState({}, '', window.location.pathname + '?' + urlParams.toString());
  }
}

const requestAndSaveCurrencyExchange = async () => {
  const currencyExchange = getCurrencyExchange();
  const currentCurrency = getCurrentCurrency();
  let currency = currentCurrency;
  let destinationDefaultCurrency;

  // Check query string first
  const queryCurrency = getCurrencyFromQueryString();
  if (queryCurrency) {
    currency = queryCurrency;
    removeCurrencyFromQueryString();

    let needsRefresh = false;
    if (currentCurrency && currentCurrency.base_currency !== currency.base_currency) {
      needsRefresh = true;
    }

    if (!currentCurrency) {
      destinationDefaultCurrency = selectCurrency(currencies, defaultCurrency);
      if (destinationDefaultCurrency.base_currency !== currency.base_currency) {
        needsRefresh = true;
      }
    }

    if (needsRefresh) {
      changeStoreSession(currency);
      return -99;
    }
  } else if (!currency && (currencies && currencies.length > 1)) {
    // If no query param, try IP detection
    const aux = await currencyFromIp();
    currency = selectCurrency(currencies, aux);
  }

  const currencyExistsInConfiguration = currencies.some(c => c.code === currency?.code);
  if (!currencyExistsInConfiguration) {
    currency = selectCurrency(currencies, defaultCurrency);
  }

  // transform currency from string to object, if proceed
  if (currency && !currency.code && currencies) {
    currency = selectCurrency(currencies, currency);
  }

  if (!currentCurrency) {
    // at this point, we don't have current currency (new session)
    // and the default destination currency has a different sales id that the new auto-detected currency
    destinationDefaultCurrency = selectCurrency(currencies, defaultCurrency);
    if (destinationDefaultCurrency.base_currency !== currency.base_currency) {
      setChangeStoreDisclaimer(currency);
      currency = destinationDefaultCurrency;
    }
  }

  setCurrentCurrency(currency);

  if (currency) {
    if (currency.code && (!currencyExchange || (currencyExchange && currencyExchange.base !== currency.base_currency))) {
      getCurrencyExchangeService({ currency: currency.base_currency })
      .then(r => {
        setCurrencyExchange(r);
      })
      .catch(e => console.log('error fetching currencyExchange'));
    }
  }
};

const generateSessionWithInntopia = (isRenew = false, forceReload) => {
  const userAgent = checkDevice();
  const sales_id = getSalesId();

  generateInntopiaSession({ salesId: sales_id })
    .then (r => {
      setSession({
        sales_id: sales_id,
        uuid: r.SessionId,
        customer: {},
        cart: {},
        browser: userAgent,
        created_at: new Date().getTime(),
        updated_at: new Date().getTime()
      });

      if (isRunTime() && isRenew && forceReload) {
        handleRefresh();
      }
    })
    .catch(e => console.log('error generating session', e));

  return;
}

const updateSessionWithInntopia = (session) => {
  setSession({
    ...session,
    updated_at: new Date().getTime()
  });

  return;
}

const checkForSession = async (renewSession = false, forceReload = true) => {
  const session = getSession();

  if (INNTOPIA_SALES_ID) {
    if (session && session.updated_at) {
      const sessionDate = new Date(session.updated_at);
      const now = new Date();
      const diff = differenceInHours(sessionDate, now);
      if (diff > 12) {
        renewSession = true;
      }
      // flamingo session in inntopia environment
      if (session.status) {
        renewSession = true;
      }
    }

    if (!getCurrencyExchange() || forceReload) {
      const response = await requestAndSaveCurrencyExchange();
      if (response === -99) {
        // case to not execute the rest of the code
        // in this case we are reloading the page changing the currency store
        return;
      }
    }

    if (!session || (session && !session.sales_id) || renewSession) {
      generateSessionWithInntopia(renewSession, forceReload);
    } else {
      updateSessionWithInntopia(session);
    }

    return; // to no execute flamingo system
  }

  if (session && session.updated_at) {
    const sessionDate = fromISO(session.updated_at);
    const now = new Date();
    const diff = differenceInHours(sessionDate, now);
    if (diff > 12) {
      generateSession();
    }
  }

  if (!session) {
    generateSession();
  } 
};

const checkSessionStatusOnServer = () => {
  const userAgent = checkDevice();
  return new Promise((resolve, reject) => {
    if (!getSession()) resolve();
    axios
    .post(`${CONNECT}/checkout/session`, {
      client: CONNECT_CLIENT,
      session: getSession().uuid,
      userAgent: userAgent,
    })
    .catch(() => resolve())
    .then(response => {
      resolve(response.data);
    })
  })
}

const renewSession = () => {
  return new Promise((resolve, reject) => {
    axios
  .post(`${CONNECT}/checkout/session/new`, {
    client: CONNECT_CLIENT,
    session: getSession().uuid,
  })
  .then((r) => {
    clearStorage();
    setSession(r.data);
    resolve(true);
  })
  .catch(() => reject())
  })
}

export { checkForSession, renewSession, checkSessionStatusOnServer, changeStoreSession };
