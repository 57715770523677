import React from "react";

export default function NoResultsIcon(props: any) {
  return (
    <svg 
      version="1.1" 
      id="Layer_1" 
      xmlns="http://www.w3.org/2000/svg" 
      xmlnsXlink="http://www.w3.org/1999/xlink" 
      x="0px" 
      y="0px"
      viewBox="0 0 440 300" 
      style={{enableBackground: 'new 0 0 440 300'}} 
      {...props}
    >
      <g>
        <path 
          fill="none" 
          stroke="#A6A6AE" 
          strokeWidth="16" 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          d="M220,248.1c54.3,0,98.1-43.8,98.1-98.1S274.3,51.9,220,51.9s-98.1,43.8-98.1,98.1C121.9,204.3,165.7,248.1,220,248.1z"
        />
        <path 
          fill="none" 
          stroke="#A6A6AE" 
          strokeWidth="40" 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          d="M345.6,275.6l-45.5-45.5"
        />
        <g>
          <line 
            fill="none" 
            stroke="#A6A6AE" 
            strokeWidth="16" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            x1="189" 
            y1="118.9" 
            x2="251" 
            y2="181.1"
          />
          <line 
            fill="none" 
            stroke="#A6A6AE" 
            strokeWidth="16" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            x1="251" 
            y1="118.9" 
            x2="189" 
            y2="181.1"
          />
        </g>
      </g>
    </svg>
  );
}
