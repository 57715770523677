import { getSalesId } from './general';

import request from 'src/utils/request';
import { CONNECT } from 'src/utils/environment';

export function getSuppliersList (data) {
  const inntopiaSalesId = getSalesId(data.salesId);

  let parameters = {
    id: inntopiaSalesId,
    salesId: inntopiaSalesId
  };

  if (data.superCategoryId) {
    parameters.productSuperCategoryId = data.superCategoryId;
  }
  if (data.productCategoryId && data.productCategoryId !== parameters.productSuperCategoryId) {
    parameters.productCategoryId = data.productCategoryId;
  }
  if (data.extended) {
    parameters.extended = 1;
  }

  return new Promise((resolve, reject) => {
    request({
      url: `${CONNECT}/getSuppliers`,
      method: 'GET',
      params: parameters
    })
    .then((response) => {
      const data = response.data || null;

      if (data.result) {
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/getSuppliersList', err);
      reject(err)
    });
  })
};
