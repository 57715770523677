import React from "react";
import Link from "src/components/link";
import ImageV2 from "src/components/image/image.v2";

type LogoProps = {
    className?: string;
    id: string;
    link?: string;
    logo: TImage;
}

export default class LogoImage extends React.Component<LogoProps> {

    get isSvg() {
        return this.props.logo.type?.indexOf("svg") !== -1;
    }

    renderSVG() {
        return <ImageV2 id={this.props.id} useImageKit={false} src={this.props.logo.imageFile} />;
    }

    renderIMG() {
        return <ImageV2 imageFormat="png" useImageKit={true} width={250} height={60} style={{objectFit: 'contain', objectPosition: 'center'}} src={this.props.logo.imageFile} />
    }

    render() {
        return <Link href={this.props.link} title={this.props.link} className={`max-h-[60px] max-w-[250px] ${this.props.className || ''}`}>
            {this.isSvg ? this.renderSVG() : this.renderIMG() }
        </Link>;
    }

}