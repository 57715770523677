import React from "react";

import NavigationBarLink from "./navigationbar.link";
import Icon from "src/components/icon";

import NavigationBarSubMenuWithLink from "./navigationbar.submenu.with.link";
import { getLanguageField } from "src/utils/language";

type Props = {
    key: string;
    depth: number;
    language: string;
    defaultLanguage: string;
    item: TNavigationItem;
    collapse_first_level_items: boolean;
    onClick?: () => void;
    onDimension?: (width: number) => void;
}

type State = {
    isCollapsed: boolean;
}

export default class NavigationBarSubMenu extends React.Component<Props, State> {
    state = {
        isCollapsed: true
    }

    public get label() {
        return getLanguageField(this.props.item.translations, this.props.language, 'label');
    }

    private get style(): any {
        return {
            '--text-color': this.props.item.item_color?.value,
            '--hover-color': this.props.item.item_hover_color?.value,
        }
    }

    private get items(){
        return (this.props.item?.items || []).filter(item => item.hide !== "yes")
    }

    public getChevron = () => {
        if (!this.props.collapse_first_level_items || !this.items.length) return null;
        return <Icon className={`h-4 w-4 ml-1 cursor-pointer`} icon={this.state.isCollapsed ? "ChevronDown" : "ChevronUp"} />
    }

    render(): React.ReactNode {
        const {label, getChevron, props} = this;
        const {language, defaultLanguage, collapse_first_level_items} = props;

        return <>
            <div style={this.style} onClick={() => this.setState({ isCollapsed: !this.state.isCollapsed })} className="navBarText navigation-bar-submenu-label text-[color:var(--text-color)] hover-[color:var(--hover-color)]">{label} {getChevron()}</div>

            {this.state.isCollapsed && collapse_first_level_items ? null : (this.items || []).map((item, idx) => {
                if (item.item_type === "Link") return <NavigationBarLink onDimension={this.props.onDimension} onClick={this.props.onClick} depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`navbar_link_${idx}`} item={item} />
                if (item.item_type === "Menu") return <NavigationBarSubMenu collapse_first_level_items={collapse_first_level_items} onDimension={this.props.onDimension} onClick={this.props.onClick} depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`navbar_menu_${idx}`} item={item} />
                if (item.item_type === "Menu with link") return <NavigationBarSubMenuWithLink collapse_first_level_items={collapse_first_level_items} onDimension={this.props.onDimension} onClick={this.props.onClick} depth={this.props.depth + 1} language={language} defaultLanguage={defaultLanguage} key={`navbar_menulink_${idx}`} item={item} />
                return null;
            })}
        </>;
    }
}